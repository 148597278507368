import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/namespace
import { useSpring, animated } from '@react-spring/web';

import { getScale } from '../helpers/getScale.js';

export const View = ({ width, height, containerSize, children, isMini }) => {
  const contentSizeState = useState({ width: 0, height: 0 });
  const contentSize = contentSizeState[0];

  const resize = (scaleWidth, scaleHeight) => {
    const scaledContainerSize = {
      width: containerSize.width * scaleWidth,
      height: containerSize.height * scaleHeight
    };

    return getScale(contentSize, scaledContainerSize);
  };

  const delay = 10;
  const config = {
    mass: 3,
    friction: 45
  };

  const getHeight = () => {
    return isMini ? 10 : 83;
  };

  const sizeSpring = useSpring({
    to: {
      width: `${width * 99}%`,
      height: `${height * 99}%`
    },
    config: config,
    delay: delay
  });

  const scaleSpring = useSpring({
    from: {
      scale: 1.0,
      height: `${getHeight()}vh`
    },
    to: {
      scale: width == 0.445 && height == 0.5 ? 1 : resize(width, height),
      height: `${height * getHeight()}vh`
    },
    config: config,
    delay: delay
  });

  return (
    <animated.div
      className={'outer-container' + (isMini ? ' mini' : '')}
      style={sizeSpring}
    >
      <animated.div className='flex-container' style={scaleSpring}>
        {React.cloneElement(React.Children.only(children), {
          contentSizeState: contentSizeState
        })}
      </animated.div>
    </animated.div>
  );
};

export default View;

View.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  containerSize: PropTypes.object,
  children: PropTypes.object,
  isMini: PropTypes.bool
};
