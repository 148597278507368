/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale
} from 'chart.js';

import { Radar } from 'react-chartjs-2';
import Annotation from 'chartjs-plugin-annotation';

import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';

ChartJS.register(
  RadialLinearScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const VectorChart = ({ jsonLayout, viewProps }) => {
  const chartRef = useRef();
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [ulid, setUlid] = useState(selectedUlids[0]);
  const [labels, setLabels] = useState([]);
  const [readings, setReadings] = useState({});
  const [name, setName] = useState('');
  const [voltageA, setVoltageA] = useState([0] * 360);
  const [voltageB, setVoltageB] = useState([0] * 360);
  const [voltageC, setVoltageC] = useState([0] * 360);
  const [hideVoltageA, setHideVoltageA] = useState(false);
  const [hideVoltageB, setHideVoltageB] = useState(false);
  const [hideVoltageC, setHideVoltageC] = useState(false);
  const [currentA, setCurrentA] = useState([0] * 360);
  const [currentB, setCurrentB] = useState([0] * 360);
  const [currentC, setCurrentC] = useState([0] * 360);
  const [hideCurrentA, setHideCurrentA] = useState(false);
  const [hideCurrentB, setHideCurrentB] = useState(false);
  const [hideCurrentC, setHideCurrentC] = useState(false);
  const color = 'rgb(255, 255, 255, 1.0)';
  const backgroundColor = 'rgb(105, 105, 105, 1.0)';

  useEffect(() => {
    var labelArray = [];

    for (var i = 0; i < 360; i += 1) {
      labelArray.push(i);
    }
    setLabels(labelArray.reverse());
    subscribe(meterReadingsEventName, (e) => {
      setReadings(e.detail?.[ulid] || {});
    });
    return () => {
      unsubscribe(meterReadingsEventName);
    };
  }, [ulid]);

  useEffect(() => {
    let ulid = null;
    let min_index = 100000;
    selectedUlids.forEach((element, index) => {
      let component = null;
      if (viewProps.subsite) {
        component = jsonLayout[viewProps.subsite].find(
          (c) => c.props.ulid === element
        );
      } else {
        component = jsonLayout.layout.find((c) => c.props.ulid === element);
      }
      if (component) {
        const type = component.component.toLowerCase();
        if (type != 'irradiance') {
          if (index < min_index) {
            ulid = element;
            min_index = index;
          }
        }
      }
    });
    setUlid(ulid);
  }, [selectedUlids]);

  useEffect(() => {
    setName(readings.name);
    let size = 360;
    let temp = new Array(size);
    for (let i = 0; i < size; i++) temp[i] = 0;
    let tempVA = temp.slice();
    let tempVB = temp.slice();
    let tempVC = temp.slice();
    let tempAA = temp.slice();
    let tempAB = temp.slice();
    let tempAC = temp.slice();

    let angleCurrentA =
      readings['power_factor_a'] > 0
        ? 0 +
          Math.round(
            (Math.acos(Math.abs(readings['power_factor_a'])) * 180) / Math.PI
          )
        : 0 -
          Math.round(
            (Math.acos(Math.abs(readings['power_factor_a'])) * 180) / Math.PI
          );
    let angleCurrentB =
      readings['power_factor_b'] > 0
        ? 120 +
          Math.round(
            (Math.acos(Math.abs(readings['power_factor_b'])) * 180) / Math.PI
          )
        : 120 -
          Math.round(
            (Math.acos(Math.abs(readings['power_factor_b'])) * 180) / Math.PI
          );
    let angleCurrentC =
      readings['power_factor_c'] > 0
        ? 240 +
          Math.round(
            (Math.acos(Math.abs(readings['power_factor_c'])) * 180) / Math.PI
          )
        : 240 -
          Math.round(
            (Math.acos(Math.abs(readings['power_factor_c'])) * 180) / Math.PI
          );
    angleCurrentA = angleCurrentA < 0 ? 360 + angleCurrentA : angleCurrentA;
    angleCurrentB = angleCurrentB < 0 ? 120 + angleCurrentB : angleCurrentB;
    angleCurrentC = angleCurrentC < 0 ? 240 + angleCurrentC : angleCurrentC;
    setHideVoltageA(readings['volts_reading_an'] == 0);
    setHideVoltageB(readings['volts_reading_bn'] == 0);
    setHideVoltageC(readings['volts_reading_cn'] == 0);
    setHideCurrentA(readings['amps_reading_a'] == 0);
    setHideCurrentB(readings['amps_reading_b'] == 0);
    setHideCurrentC(readings['amps_reading_c'] == 0);
    tempVA[0] = readings['volts_reading_an'] == 0 ? 0 : 100;
    tempVB[120] = readings['volts_reading_bn'] == 0 ? 0 : 100;
    tempVC[240] = readings['volts_reading_cn'] == 0 ? 0 : 100;
    tempAA[angleCurrentA] = readings['amps_reading_a'] == 0 ? 0 : 60;
    tempAB[angleCurrentB] = readings['amps_reading_b'] == 0 ? 0 : 60;
    tempAC[angleCurrentC] = readings['amps_reading_c'] == 0 ? 0 : 60;
    setVoltageA(tempVA.reverse());
    setVoltageB(tempVB.reverse());
    setVoltageC(tempVC.reverse());
    setCurrentA(tempAA.reverse());
    setCurrentB(tempAB.reverse());
    setCurrentC(tempAC.reverse());
  }, [readings]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        title: {
          display: true,
          padding: { top: -40, bottom: 30 },
          position: 'center',
          text: () => {
            if (chartRef && chartRef.current) {
              return ['Voltage + Current', 'Vector Graph'];
            } else {
              return 'No data.';
            }
          },
          color: color,
          font: { size: 13 }
        },
        position: 'right'
      },
      tooltip: {
        enabled: false
      }
    },
    responsive: true,
    scales: {
      r: {
        min: 0,
        suggestedMax: 100,
        grid: {
          lineWidth: 2,
          drawTicks: false,
          borderDash: [5, 2],
          color: 'rgb(255, 255, 255, 0.5)'
        },
        startAngle: 90,
        ticks: {
          stepSize: 20,
          display: false
        },
        pointLabels: {
          callback: (value) => {
            return value % 120 == 0 ? value + '\u00B0' : '';
          }
        },
        angleLines: {
          display: true,
          color: function (context) {
            return (context.index + 1) % 120 == 0
              ? 'rgb(255, 255, 255, 0.5)'
              : 'rgb(0, 0, 0, 0)';
          },
          lineWidth: 2
        }
      }
    },
    animation: false
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Current A',
        data: currentA,
        borderColor: 'rgb(0, 255, 255)',
        fill: 'origin',
        order: 1,
        pointHoverBackgroundColor: 'rgb(0, 255, 255)',
        pointHoverBorderColor: 'rgb(0, 255, 255)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 0,
        hidden: hideCurrentA
      },
      {
        label: 'Current B',
        data: currentB,
        borderColor: 'rgb(255, 255, 0)',
        fill: 'origin',
        order: 1,
        pointHoverBackgroundColor: 'rgb(255, 255, 0)',
        pointHoverBorderColor: 'rgb(255, 255, 0)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 0,
        hidden: hideCurrentB
      },
      {
        label: 'Current C',
        data: currentC,
        borderColor: 'rgb(255, 0, 255)',
        fill: 'origin',
        order: 1,
        pointHoverBackgroundColor: 'rgb(255, 0, 255)',
        pointHoverBorderColor: 'rgb(255, 0, 255)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 0,
        hidden: hideCurrentC
      },
      {
        label: 'Voltage A',
        data: voltageA,
        borderColor: 'rgb(0, 255, 0)',
        fill: 'origin',
        order: 2,
        pointHoverBackgroundColor: 'rgb(0, 255, 0)',
        pointHoverBorderColor: 'rgb(0, 255, 0)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 0,
        hidden: hideVoltageA
      },
      {
        label: 'Voltage B',
        data: voltageB,
        borderColor: 'rgb(255, 0, 0)',
        fill: 'origin',
        order: 2,
        pointHoverBackgroundColor: 'rgb(255, 0, 0)',
        pointHoverBorderColor: 'rgb(255, 0, 0)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 0,
        hidden: hideVoltageB
      },
      {
        label: 'Voltage C',
        data: voltageC,
        borderColor: 'rgb(0, 0, 255)',
        fill: 'origin',
        order: 2,
        pointHoverBackgroundColor: 'rgb(0, 0, 255)',
        pointHoverBorderColor: 'rgb(0, 0, 255)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 0,
        hidden: hideVoltageC
      }
    ]
  };

  if (labels.length > 0) {
    return <Radar ref={chartRef} options={options} data={data} />;
  }
};

VectorChart.propTypes = {
  jsonLayout: PropTypes.object,
  viewProps: PropTypes.object
};
