/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable @shopify/jsx-prefer-fragment-wrappers */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { renderReading } from '../readings.js';
import { isOnline } from '../helpers/isOnline.js';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import { Arrow } from './arrow.js';

export const PDU = ({
  name,
  ulid,
  xaxis,
  yaxis,
  viewProps,
  overrideName,
  arrowLocation,
  secondArrow,
  value,
  timezone,
  isTabletOrMobile,
  subsite,
  isSubsite
}) => {
  const [pduName, setPduName] = useState(overrideName || name);
  const [readings, setReadings] = useState({});
  const [pduReading, setPduReading] = useState('');
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(true);
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  // eslint-disable-next-line no-unused-vars
  const [views, setViews] = viewProps.viewsState;
  const viewMap = viewProps.viewMap;
  const [color, setColor] = useState('white');
  const [arrowDirection, setArrowDirection] = useState('into');

  const directionReading = 'kw_reading';

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) => {
      setReadings(e.detail?.[ulid] || {});
    });
    return () => unsubscribe(meterReadingsEventName);
  }, [ulid]);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (Object.keys(readings).length !== 0) {
      _online = isOnline(parseInt(readings?.read_at || '0') * 1000);
      if (!overrideName && 'name' in readings) {
        setPduName(readings?.name || pduName);
      } else if (overrideName) {
        setPduName(overrideName);
      }
      if (selectedReading in readings)
        setPduReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            readings,
            _online,
            timezone,
            isTabletOrMobile
          )
        );
      if (directionReading in readings) {
        // if (readings[directionReading] > 0) {
        //   setArrowDirection('away');
        // } else {
        //   setArrowDirection('into');
        // }
        setArrowDirection('into');
      }
    } else {
      setPduName(name);
      setPduReading('');
    }
    // setOnline(_online);
  }, [readings, selectedReading]);

  const getOppositeArrowLocation = () => {
    switch (arrowLocation) {
      case 'left':
        return 'right';
      case 'right':
        return 'left';
      case 'up':
        return 'down';
      case 'down':
        return 'up';
      default:
        return;
    }
  };

  const getOppositeArrowDirection = () => {
    return arrowDirection == 'away' ? 'into' : 'away';
  };

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`,
    borderColor: `${color}`
  };

  const getReadingsColor = () => {
    // if (Number.parseFloat(reading) >= 0) {
    return <div className='positive-readings'>+{value} kW</div>;
    // }
    // else {
    //   return <div className='negative-readings'>{reading}</div>;
    // }
  };

  const onClick = (e, ulid) => {
    if (!isTabletOrMobile && ulid) {
      if (subsite) {
        if (isSubsite) {
          if (selectedUlids.includes(ulid)) {
            const ulids = selectedUlids.filter((u) => u !== ulid);
            setSelectedUlids(ulids);
            if (ulids.length === 0) {
              setViews([viewMap.subsite, subsite]);
            }
          } else {
            setViews([viewMap.subsite, viewMap.meter, viewMap.chart, subsite]);
            setSelectedUlids([ulid, ...selectedUlids]);
          }
        } else {
          setViews([viewMap.subsite, subsite]);
        }
      } else {
        if (selectedUlids.includes(ulid)) {
          const ulids = selectedUlids.filter((u) => u !== ulid);
          setSelectedUlids(ulids);
          if (ulids.length === 0) {
            setViews([viewMap.site]);
          }
        } else {
          setViews([viewMap.site, viewMap.meter, viewMap.chart]);
          setSelectedUlids([ulid, ...selectedUlids]);
        }
      }
    }
    e.stopPropagation();
  };

  const gray = '#474747';

  useEffect(() => {
    if (selectedUlids.includes(ulid)) {
      setColor('yellow');
    } else if (!online) {
      setColor(gray);
    } else {
      setColor('white');
    }
  }, [online, selectedUlids]);

  const onMouseOver = () => {
    if (!selectedUlids.includes(ulid)) setColor('yellow');
  };

  const onMouseOut = () => {
    if (!selectedUlids.includes(ulid))
      online ? setColor('white') : setColor(gray);
  };

  return (
    <div
      className='apparent-module'
      id={ulid}
      style={customStyle}
      onMouseOut={(e) => onMouseOut(e)}
      onMouseOver={(e) => onMouseOver(e)}
      onClick={(e) => onClick(e, ulid)}
    >
      <div className='pdu' style={{ borderColor: `${color}` }}>
        <div
          className='pdu-ethernet-container'
          style={{ opacity: `${online ? 1.0 : 0.3}` }}
        >
          <div className='pdu-ethernet-box-1' />
          <div className='pdu-ethernet-box-2' />
          <div className='pdu-ethernet-box-3' />
          <div className='pdu-ethernet-horizontal' />
          <div className='pdu-ethernet-vertical-1' />
          <div className='pdu-ethernet-vertical-2' />
          <div className='pdu-ethernet-vertical-3' />
        </div>
        <div
          className='pdu-outlet-container'
          style={{ opacity: `${online ? 1.0 : 0.3}` }}
        >
          <div className='pdu-outlet-plug-1' />
          <div className='pdu-outlet-plug-2' />
          <div className='pdu-outlet-plug-3' />
        </div>
        <img
          src='/favicon_apparent.png'
          className='pdu-logo'
          alt='Apparent Logo'
          style={{ opacity: `${online ? 1.0 : 0.3}` }}
        />
        <div
          className='pdu-outlet-container'
          style={{ opacity: `${online ? 1.0 : 0.3}` }}
        >
          <div className='pdu-outlet-plug-1' />
          <div className='pdu-outlet-plug-2' />
          <div className='pdu-outlet-plug-3' />
        </div>
        <div className='pdu-text-wrapper'>
          <div className='pdu-name' style={{ color: `${color}` }}>
            {pduName}
          </div>
          <div
            className='meter-readings'
            style={{ top: '0%', borderColor: `${color}` }}
          >
            {value ? getReadingsColor() : pduReading}
          </div>
        </div>
        <Arrow
          location={arrowLocation}
          direction={arrowDirection}
          component='pdu'
          online={online}
        />
        {secondArrow == true ? (
          <Arrow
            location={getOppositeArrowLocation()}
            direction={getOppositeArrowDirection()}
            component='pdu'
            online={online}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

PDU.propTypes = {
  name: PropTypes.string,
  ulid: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  overrideName: PropTypes.string,
  arrowLocation: PropTypes.string,
  secondArrow: PropTypes.bool,
  value: PropTypes.number,
  viewProps: PropTypes.object,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool,
  subsite: PropTypes.string,
  isSubsite: PropTypes.bool
};
