/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable @shopify/jsx-prefer-fragment-wrappers */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Arrow } from './arrow';

export const BuildingUnit = ({
  name,
  ulid,
  xaxis,
  yaxis,
  viewProps,
  overrideName,
  arrowLocation,
  value,
  timezone,
  isTabletOrMobile
}) => {
  const [buildingName, setBuildingName] = useState(overrideName || name);
  const [reading, setReading] = useState('');
  const [readings, setReadings] = useState({});
  // const [selectedReading, setSelectedReading] = useState(() => {
  //   if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
  //     return localStorage.getItem('selectedReading'.concat(ulid));
  //   }
  //   return 'kw_reading';
  // });
  // const [online, setOnline] = useState(false);
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  // eslint-disable-next-line no-unused-vars
  const [views, setViews] = viewProps.viewsState;
  const viewMap = viewProps.viewMap;
  const [color, setColor] = useState('white');

  // useEffect(() => {
  //   subscribe(meterReadingsEventName, (e) => {
  //     setReadings(e.detail?.[ulid] || {});
  //   });
  //   return () => unsubscribe(meterReadingsEventName);
  // }, [ulid]);

  useEffect(() => {
    //   localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    //   let _online = false;
    //   if (Object.keys(readings).length !== 0) {
    //     _online =
    //       'read_at' in readings
    //         ? isOnline(parseInt(readings.read_at) * 1000)
    //         : true;
    if (!overrideName && 'name' in readings) {
      setBuildingName(readings.name);
    } else if (overrideName) {
      setBuildingName(overrideName);
    }
    //     if (selectedReading in readings)
    //       setReading(
    //         renderReading(
    //           selectedReading,
    //           setSelectedReading,
    //           readings,
    //           _online,
    //           timezone,
    //           isTabletOrMobile
    //         )
    //       );
    //   } else {
    //     setReading('');
    //   }
    //   setOnline(_online);
  }, [readings, ulid]);

  const getReadingsColor = () => {
    // if (Number.parseFloat(reading) >= 0) {
    return <div className='positive-readings'>+{value} kW</div>;
    // } else {
    //   return <div className='negative-readings'>{reading}</div>;
    // }
  };

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  const getOppositeArrowLocation = () => {
    switch (arrowLocation) {
      case 'left':
        return 'right';
      case 'right':
        return 'left';
      case 'up':
        return 'down';
      case 'down':
        return 'up';
      default:
        return;
    }
  };

  // const onClick = (e, ulid) => {
  //   if (!isTabletOrMobile) {
  //     if (selectedUlids.includes(ulid)) {
  //       const ulids = selectedUlids.filter((u) => u !== ulid);
  //       setSelectedUlids(ulids);
  //       if (ulids.length === 0) {
  //         setViews([viewMap.site]);
  //       }
  //     } else {
  //       setViews([viewMap.site, viewMap.meter, viewMap.chart]);
  //       setSelectedUlids([ulid, ...selectedUlids]);
  //     }
  //   }
  //   e.stopPropagation();
  // };

  // const gray = '#474747';

  useEffect(() => {
    if (selectedUlids.includes(ulid)) {
      setColor('yellow');
    } else {
      setColor('white');
    }
  }, [selectedUlids]);

  const onMouseOver = () => {
    if (!selectedUlids.includes(ulid)) setColor('yellow');
  };

  const onMouseOut = () => {
    setColor('white');
    // if (!selectedUlids.includes(ulid))
    //   true ? setColor('white') : setColor(gray);
  };

  return (
    <div
      className='apparent-module building'
      id={ulid}
      style={customStyle}
      onMouseOut={(e) => onMouseOut(e)}
      onMouseOver={(e) => onMouseOver(e)}
    >
      <div className='building-name' style={{ color: `${color}` }}>
        {buildingName}
      </div>
      <div className='building-roof' style={{ borderColor: `${color}` }} />
      <div className='building-outside' style={{ borderColor: `${color}` }}>
        <div className='building-window-upper' />
        <div className='building-window-upper' />
        <div className='building-window-upper' />
        <div className='building-window-upper' />
        <div className='building-window-upper' />
        <div className='building-window-floor-1' />
        <div className='building-window-floor-2' />
        <div
          className='building-door'
          style={{ backgroundColor: `${color}` }}
        />
        <div className='building-door-inner' />
        <div
          className='readings'
          style={{
            margin: '5px',
            position: 'absolute',
            left: '12px',
            top: '80px',
            width: '80px',
            background: 'black'
          }}
        >
          {getReadingsColor()}
        </div>
      </div>
      <div className='tree-container'>
        <div className='tree-leaves' />
        <div className='tree-trunk' />
      </div>
      <Arrow
        location={arrowLocation}
        direction='into'
        component='building'
        online
      />
    </div>
  );
};

BuildingUnit.propTypes = {
  name: PropTypes.string,
  ulid: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  overrideName: PropTypes.string,
  viewProps: PropTypes.object,
  arrowLocation: PropTypes.string,
  value: PropTypes.number,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool
};
