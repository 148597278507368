/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @shopify/jsx-prefer-fragment-wrappers */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getScale } from '../helpers/getScale.js';
import { Meter } from '../components/meter.js';
import { Solar } from '../components/solar.js';
import { Battery } from '../components/battery.js';
import { Transformer } from '../components/transformer.js';
import { MiniTransformer } from '../components/miniTransformer.js';
import { HorizontalConnector } from '../components/horizontalConnector.js';
import { VerticalConnector } from '../components/verticalConnector.js';
import { Connector } from '../components/connector.js';
import { BusDispenser } from '../components/busDispenser.js';
import { ElectricBus } from '../components/electricBus.js';
import { PowerGrid } from '../components/powerGrid.js';
import { Label } from '../components/label.js';
import { Irradiance } from '../components/irradiance.js';
import { TruckCharger } from '../components/truckCharger.js';
import { Cooler } from '../components/cooler.js';
import { Server } from '../components/serverRack.js';
import { PDU } from '../components/pdu.js';
import { GPU } from '../components/gpu.js';
import { Selector } from '../components/selector.js';
import { OverlapConnector } from '../components/overlapConnector.js';
import { BuildingUnit } from '../components/buildingUnit.js';

const componentMap = {
  Meter: Meter,
  Solar: Solar,
  Battery: Battery,
  Transformer: Transformer,
  MiniTransformer: MiniTransformer,
  BusDispenser: BusDispenser,
  ElectricBus: ElectricBus,
  PowerGrid: PowerGrid,
  HorizontalConnector: HorizontalConnector,
  VerticalConnector: VerticalConnector,
  OverlapConnector: OverlapConnector,
  Connector: Connector,
  Label: Label,
  Irradiance: Irradiance,
  TruckCharger: TruckCharger,
  Cooler: Cooler,
  Server: Server,
  PDU: PDU,
  GPU: GPU,
  Selector: Selector,
  BuildingUnit: BuildingUnit
};

export const SubsiteView = ({
  online,
  dataSource,
  jsonLayout,
  ulids,
  contentSizeState,
  containerSize,
  viewProps,
  isTabletOrMobile
}) => {
  const [content, setContent] = useState('Loading...');
  const [scale, setScale] = useState(1.1);
  // eslint-disable-next-line no-unused-vars
  const [contentSize, setContentSize] = contentSizeState;
  const [layoutSize, setLayoutSize] = useState({ width: 0, height: 0 });
  const [views, setViews] = viewProps.viewsState;
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [subsiteLayout, setSubsiteLayout] = useState({});
  const viewMap = viewProps.viewMap;
  const subsite = viewProps.subsite;

  useEffect(() => {
    if (jsonLayout && subsite) {
      setSubsiteLayout(jsonLayout[subsite]);
    }
  }, [jsonLayout, subsite]);

  useEffect(() => {
    const newScale = getScale(layoutSize, containerSize);
    setScale(newScale);
    setContentSize({
      width: layoutSize.width * newScale,
      height: layoutSize.height * newScale
    });
  }, [layoutSize, containerSize]);

  // https://www.pluralsight.com/guides/how-to-render-a-component-dynamically-based-on-a-json-config
  const buildContent = (subsiteLayout) => {
    const filteredLayout = subsiteLayout.filter(
      (c) => c.component in componentMap
    );
    return filteredLayout.map((c, i) =>
      React.createElement(componentMap[c.component], {
        ...c.props,
        // eslint-disable-next-line react/no-array-index-key
        key: i,
        dataSource: dataSource,
        viewProps: viewProps,
        timezone: jsonLayout.timezone,
        isTabletOrMobile: isTabletOrMobile,
        subsite: subsite,
        isSubsite: true
      })
    );
  };

  const getLayoutSize = (layout) => {
    const gridCellSize = 40;
    let maxX = 0;
    let maxY = 0;
    layout.forEach((c) => {
      if (c.props.xaxis > maxX) maxX = c.props.xaxis;
      if (c.props.yaxis > maxY) maxY = c.props.yaxis;
    });

    return {
      width: (maxX + 4) * gridCellSize + 10,
      height: (maxY + 4) * gridCellSize
    };
  };

  // eslint-disable-next-line no-unused-vars
  const DEFAULT_READING = 'kw_reading';

  useEffect(() => {
    if (Object.keys(subsiteLayout).length !== 0) {
      const _content = buildContent(subsiteLayout);
      setLayoutSize(getLayoutSize(_content));
      setContent(_content);
      const _ulids = subsiteLayout
        .filter((c) => c.props.ulid !== undefined)
        .map((c) => c.props.ulid);
      viewProps.setUlids(ulids.concat(_ulids));

      const _ulidMaps = subsiteLayout
        .filter((c) => c.props.ulid !== undefined && c.props.name !== undefined)
        .map((c) => c.props);
      viewProps.setUlidMap(_ulidMaps);
    }
  }, [subsiteLayout, selectedUlids, views]);

  const onClick = (e) => {
    e.stopPropagation();
    setViews([viewMap.subsite, subsite]);
    setSelectedUlids([]);
  };

  return (
    <div>
      <div onClick={(e) => onClick(e)} style={{ transform: `scale(${scale})` }}>
        <div
          className={`site-layout ${!online ? 'site-offline' : ''}`}
          style={{
            width: `${layoutSize.width}px`,
            height: `${layoutSize.height}px`
          }}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

SubsiteView.propTypes = {
  online: PropTypes.bool,
  dataSource: PropTypes.object,
  jsonLayout: PropTypes.object,
  ulids: PropTypes.array,
  contentSizeState: PropTypes.array,
  containerSize: PropTypes.object,
  viewProps: PropTypes.object,
  isTabletOrMobile: PropTypes.bool
};
